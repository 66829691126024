import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle03,
  CBtnList,
  CSearch,
  CCard,
  LWifi,
  CGeneralModal,
  CPlanMedia03,
  CCard03,
  LContact03,
  CTable,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlag02, setModalFlag02] = useState(false);
  const [modalFlag03, setModalFlag03] = useState(false);
  const [modalFlag04, setModalFlag04] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };

  const openModal02 = (e: any) => {
    e.stopPropagation();
    setModalFlag02(!modalFlag02);
  };

  const openModal03 = (e: any) => {
    e.stopPropagation();
    setModalFlag03(!modalFlag03);
  };

  const openModal04 = (e: any) => {
    e.stopPropagation();
    setModalFlag04(!modalFlag04);
  };

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: '滞在中のサービス',
            sub: <>FACILITIES</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/facility/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/facility/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <CSearch formId="global" />
      <section className="l_sect">
        <LWrap>
          <CSectTitle03 title="ご朝食" />
          <CCard
            exClass="u_mb40"
            data={[
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility.png',
                  alt: '',
                },
                title: <>エグゼクティブラウンジ</>,
                content: (
                  <>
                    スイート、エグゼクティブフロアにご宿泊のお客様は、専用ラウンジでの朝食ブッフェをご利用いただけます。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility02.png',
                  alt: '',
                },
                title: <>シェフズダイニング シンフォニー</>,
                content: (
                  <>
                    窓越しに望む滝の流れを感じながら、ゆったりとご朝食をお楽しみください。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility03.png',
                  alt: '',
                },
                title: <>ルームサービス</>,
                content: (
                  <>
                    ロイヤルパークホテルの味をお部屋でおたのしみいただけます。
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            exClass="u_mb80"
            data={[
              {
                label: '朝食のご案内',
                color: 'borderBlack',
                link: {
                  href: '/stay/breakfast/',
                },
              },
            ]}
          />
          <CCard
            exClass="u_mb80"
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility04.png',
                  alt: '',
                },
                title: <>ルームサービス</>,
                content: (
                  <>
                    ロイヤルパークホテルの味をお部屋でおたのしみいただけます。
                    <br />
                    プライベートな空間で、ゆったりと朝のひとときをお過ごしください。
                  </>
                ),
                btn: {
                  label: 'ルームサービス',
                  link: {
                    href: '/private/3mchpxdzfuxz/',
                  },
                  color: 'bgWhite03',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility05.png',
                  alt: '',
                },
                title: <>記念日・プロポーズ</>,
                content: (
                  <>
                    お誕生日や結婚記念日、お客様の特別な日の演出は、ロイヤルパークホテルにお任せください。
                  </>
                ),
                btn: {
                  label: '記念日・プロポーズ',
                  link: {
                    href: '/anniversary/',
                  },
                  color: 'bgWhite03',
                },
              },
            ]}
          />

          <CCard03
            exClass="u_mb80"
            data={[
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility06.png',
                  alt: '',
                },
                title: <>お子様連れのお客様へ</>,
                content: (
                  <>
                    ご家族で安心してお過ごしいただけるよう、お子様向けのアメニティや貸出備品などをご用意しております。
                  </>
                ),
                btn: {
                  label: 'お子様連れのお客様へ',

                  link: {
                    href: '/stay/child/',
                  },
                  color: 'bgWhite03',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility07.png',
                  alt: '',
                },
                title: <>ご宿泊者専用ジム</>,
                content: (
                  <>
                    ご宿泊のお客様専用の24時間ご利用いただけるプライベート空間で、心地良く充実したホテルライフをお過ごしください。
                  </>
                ),
                btn: {
                  label: '詳しく見る',
                  icon: 'modal',
                  tag: 'button',
                  onClick: openModal02,
                  link: {
                    href: '#',
                  },
                  color: 'bgWhite03',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility08.png',
                  alt: '',
                },
                title: <>ビジネスサービス</>,
                content: (
                  <>
                    インターネット接続、ミーティングルーム手配など、ビジネスにお役立ちのサービスを取り揃えております。
                  </>
                ),
                btn: {
                  label: '詳しく見る',
                  icon: 'modal',
                  tag: 'button',
                  onClick: openModal,
                  link: {
                    href: '#',
                  },
                  color: 'bgWhite03',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility09.png',
                  alt: '',
                },
                title: <>観光に便利なサービス</>,
                content: (
                  <>
                    無料巡回バスや中央区コミュニティサイクルをご利用いただけます。ホテルまでのアクセスやご滞在中の周辺観光にぴったりです。
                  </>
                ),
                btn: {
                  label: '観光に便利なサービス',
                  link: {
                    href: '/about/tourism/',
                  },
                  color: 'bgWhite03',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility10.png',
                  alt: '',
                },
                title: <>インルームリラクゼーションサービス</>,
                content: (
                  <>
                    ご滞在中、お部屋にてリラクゼーションサービスをご利用いただけます。プライベート空間で、ごゆっくりと癒しのひとときをご堪能ください。
                  </>
                ),
                btn: {
                  label: '詳しく見る',
                  icon: 'modal',
                  tag: 'button',
                  onClick: openModal03,
                  link: {
                    href: '#',
                  },
                  color: 'bgWhite03',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/facility/img_stay_facility11.png',
                  alt: '',
                },
                title: <>ベビーシッター、ハイヤーなどの予約代行</>,
                content: (
                  <>
                    ご宿泊のお客様へ各種サービスをご提供しております。お客様の滞在がより良いものとなりますよう、お手伝いさせていただきます。
                  </>
                ),
                btn: {
                  label: '詳しく見る',
                  icon: 'modal',
                  tag: 'button',
                  onClick: openModal04,
                  link: {
                    href: '#',
                  },
                  color: 'bgWhite03',
                },
              },
            ]}
          />
          {modalFlag && (
            <CGeneralModal onClose={openModal} exClass={'c_generalModal_full'}>
              {' '}
              <CPlanMedia03
                data={[
                  {
                    img: {
                      src: '/assets/images/stay/facility/img_stay_facility12.png',
                      alt: '',
                    },
                    title: <>ビジネスサポートゾーン（5F）</>,
                    text: (
                      <>
                        インターネット接続可能な個別のデスクを設置。仕切られた空間でデスクワークを行っていただけるほか、コピー、プリントアウト、スキャン可能な複合機もご用意しており、皆様のビジネスをサポートする空間としてご利用いただけます。
                      </>
                    ),
                    definition: [
                      {
                        title: <>設備</>,
                        text: (
                          <>
                            <ul className="c_circleList">
                              <li>フリースペース</li>
                              <li>PC利用（インターネット接続）可能デスク4台</li>
                              <li>複合機</li>
                            </ul>
                          </>
                        ),
                      },
                      {
                        title: <>料金</>,
                        text: (
                          <>
                            <p>コピー（白黒 1枚30円、カラー 1枚80円）</p>
                            <ul className="c_noteList">
                              <li>料金には消費税が含まれます。</li>
                            </ul>
                          </>
                        ),
                      },
                    ],
                  },
                  {
                    img: {
                      src: '/assets/images/stay/facility/img_stay_facility13.png',
                      alt: '',
                    },
                    title: <>インターネット接続のご案内</>,
                    text: (
                      <>
                        各室に有線および無線によるブロードバンドインターネット接続サービス（無料）をご用意しております。迅速さと機密保持が求められるビジネスにお役立てください。
                        また、ブロードバンドインターネット接続の他に、ダイヤルアップ接続やファクシミリにご利用いただける専用回線のご用意もございます
                        <ul className="c_noteList">
                          <li>通話料は別途ご負担いただきます</li>
                        </ul>
                      </>
                    ),
                  },
                  {
                    img: {
                      src: '/assets/images/stay/facility/img_stay_facility14.png',
                      alt: '',
                    },
                    title: <>ご宿泊者専用ミーティングルーム（18F・20F）</>,
                    text: (
                      <>
                        会議やご会合、お打合せなどには、展望に恵まれた18F・20Fのミーティングルームをご⽤意しております。ご宿泊のお客様の専⽤ルームですので、落ち着いた雰囲気の中ご利用いただけます。ホワイトボード、プロジェクター、各種ビジネス機器などのご⼿配も承ります。お気軽にご相談ください。
                      </>
                    ),
                    definition: [
                      {
                        title: <>料金</>,
                        text: <>2時間 16,500円</>,
                      },
                      {
                        title: <>超過</>,
                        text: <>1時間毎に7,700円</>,
                      },
                    ],
                    note: (
                      <>
                        <ul className="c_noteList">
                          <li>予約制となります。</li>
                          <li>禁煙室となります。</li>
                          <li>
                            スイート、エグゼクティブフロアにご宿泊のお客様は2時間無料でご利用いただけます。
                          </li>
                          <li>料金には消費税が含まれます。</li>
                        </ul>
                      </>
                    ),
                  },
                ]}
              />
            </CGeneralModal>
          )}

          {modalFlag02 && (
            <CGeneralModal
              onClose={openModal02}
              exClass={'c_generalModal_full'}
            >
              <div className="c_generalModal_facilityGym">
                <div>
                  <img
                    src="/assets/images/stay/facility/img_stay_facility15.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/stay/facility/img_stay_facility18.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
              <p className="sectLead u_mb80_pc u_pb120_sp">
                和の温かみある雰囲気に、モダンでシックなデザインを取り入れた空間には、各種トレーニングマシンを備えたジムエリアと、バランスボールやヨガマットが置かれたストレッチエリアが広がります。
                <br />
                ご宿泊のお客様専用の24時間ご利用いただけるプライベート空間で、心地良く充実したホテルライフをお過ごしください。
              </p>
              <CSectTitle03 title="施設概要" exClass=" u_mb60" />
              <CTable
                data={[
                  {
                    title: '営業時間',
                    content: <>0:00~24:00</>,
                  },
                  {
                    title: 'ご利用料金',
                    content: (
                      <>
                        <ul className="c_circleList">
                          <li>スイート、エグゼクティブフロアご宿泊者　無料</li>
                          <li>スタンダードフロアご宿泊者　　1,100円</li>
                        </ul>
                        <ul className="c_noteList u_mt10">
                          <li>料金には消費税が含まれます。</li>
                          <li>ウェア・シューズはご持参ください。</li>
                          <li>
                            16歳未満のお客様のご利用はご遠慮いただいております。
                          </li>
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: '設備',
                    content: (
                      <>
                        <div className="c_3col">
                          <div className="col">
                            <p className="u_fwb u_mb10">ジムエリア</p>
                            <ul className="c_circleList">
                              <li>有酸素マシーン 9台</li>
                              <li>ウエイトトレーニングマシーン 10台</li>
                            </ul>
                          </div>
                          <div className="col">
                            <p className="u_fwb u_mb10">ストレッチエリア</p>
                            <ul className="c_circleList">
                              <li>ヨガマット</li>
                              <li>バランスボール</li>
                              <li>マット　他、トレーニング備品</li>
                            </ul>
                          </div>
                          <div className="col">
                            <p className="u_fwb u_mb10">共通</p>
                            <ul className="c_circleList">
                              <li>ウォーターサーバー（温水･常温･冷水対応）</li>
                              <li>フェイスタオル</li>
                            </ul>
                          </div>
                        </div>
                        <ul className="c_noteList u_mt10">
                          <li>更衣室、シャワー設備はございません。</li>
                        </ul>
                      </>
                    ),
                  },
                ]}
              />
            </CGeneralModal>
          )}
          {modalFlag03 && (
            <CGeneralModal
              onClose={openModal03}
              exClass={'c_generalModal_full'}
            >
              {' '}
              <CPlanMedia03
                data={[
                  {
                    img: {
                      src: '/assets/images/stay/facility/img_stay_facility16.png',
                      alt: '',
                    },
                    title: <>インルームリラクゼーションサービス</>,
                    text: (
                      <>
                        ご滞在中、お部屋にてリラクゼーションサービスをご利用いただけます。プライベート空間で、ごゆっくりと癒しのひとときをご堪能ください。各種トリートメント内容は、
                        <a
                          href="/assets/files/pdf/relaxation_service.pdf"
                          target="blank"
                        >
                          こちら
                        </a>
                        をご確認ください。
                      </>
                    ),
                    definition: [
                      {
                        title: <>営業時間</>,
                        text: <>11:00~4:00</>,
                      },
                    ],
                  },
                ]}
              ></CPlanMedia03>
            </CGeneralModal>
          )}

          {modalFlag04 && (
            <CGeneralModal
              onClose={openModal04}
              exClass={'c_generalModal_full'}
            >
              {' '}
              <CPlanMedia03
                data={[
                  {
                    img: {
                      src: '/assets/images/stay/facility/img_stay_facility17.png',
                      alt: '',
                    },
                    title: <>ベビーシッター、ハイヤーなどの予約代行</>,
                    text: (
                      <>
                        <p>
                          ご宿泊のお客様へ各種サービスをご提供しております。お客様の滞在がより良いものとなりますよう、お手伝いさせていただきます。
                        </p>
                        <ul className="c_circleList u_mt10">
                          <li>ベビーシッターのご予約代行</li>
                          <li>ハイヤーのご予約代行</li>
                          <li>英語 、日本語 ツアーのご予約代行</li>
                          <li>通訳のご予約代行</li>
                        </ul>
                      </>
                    ),
                    definition: [
                      {
                        title: <>お問合せ</>,
                        text: <>03-3667-1111（代表）</>,
                      },
                    ],
                  },
                ]}
              ></CPlanMedia03>
            </CGeneralModal>
          )}
        </LWrap>
      </section>
      <LWifi exClass="u_bgGray" />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />

      <LContact03
        title="お問合せ"
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-3667-1111',
          subText: '（代表）',
          note: '',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
